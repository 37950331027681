import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "3.6%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "7.7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      width: "13.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Prize",
      accessor: "prize",
      width: "5.1%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].prize
          ? TableInfo.data[TableInfo.row.index].prize
          : "N/A";
      },
    },
    {
      Header: "Prize Claimed Time",
      accessor: "fulfillTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;

        return 0;
      },
      width: "9.3%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].fulfillTime
          ? TableInfo.data[TableInfo.row.index].fulfillTime.toLocaleString()
          : "";

        return dateString;
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Last updated",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : "";

        return dateString;
      },
    },
    {
      Header: "Rules/Terms",
      accessor: "rules",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].rules}
          </a>
        );
      },
    },
  ];
  return COLUMNS;
};
