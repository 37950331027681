import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "8%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "8%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      width: "8%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Marketing Opt-In",
      accessor: "optin1",
      width: "5%",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].optin1}
          </a>
        );
      },
    },
    {
      Header: "Postal",
      accessor: "postal",
      width: "4%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].postal ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].postal}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Province",
      accessor: "province",
      width: "1%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].province ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].province}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Registration Date/Time",
      sortType: "datetime",
      accessor: "creationTime",
      width: "10%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].creationTime ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()}
          </a>
        ) : (
          "N/A"
        );
      },

      // Cell: (TableInfo: TableProps) => {
      //   const dateString =
      //     TableInfo.data[TableInfo.row.index].registrationDate.toLocaleString();
      //   return dateString;
      // },
    },
    {
      Header: "Phone",
      accessor: "phone",
      width: "8%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].phone ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].phone}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    
    {
      Header: "Language",
      accessor: "language",
      width: "1%",

      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].language ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].language}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Power Equipments",
      accessor: "powerEquipment",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].powerEquipment}
          </a>
        );
      },
    },
    {
      Header: "Motor Cycles",
      accessor: "motorCycles",
      width: "10%",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].motorCycles}
          </a>
        );
      },
    },
    {
      Header: "Marine",
      accessor: "marine",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].marine}
          </a>
        );
      },
    },
    {
      Header: "ATV",
      accessor: "atv",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].atv}
          </a>
        );
      },
    },
    {
      Header: "Rules",
      accessor: "rules",
      width: "3%",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].rules}
          </a>
        );
      },
    },
    
    {
      Header: "Event Tag",
      accessor: "event",
      width: "5%",

      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${
              TableInfo.data[TableInfo.row.index].sessionKey
            }`}
          >
            {TableInfo.data[TableInfo.row.index].event}
          </a>
        );
      },
    },
  ];
  return COLUMNS;
};
