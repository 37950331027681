import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";
import axios from "axios";

function Participants() {
    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getAllParticipants", async () => {
        const res: any = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants || res.result.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants.map((p:any)=>{
            return p.metadata && p.metadata.creationTime ? {
                ...p,
                creationTime: p.metadata.creationTime
            } : p
        });
    });

    function getPower(options: string[]) {
        // console.log(options)
        return options.map((option) => {
            if (option === "L") {
                return "Lawn&Garden";
            } else if (option === "G") {
                return "Generators";
            } else if (option === "S") {
                return "Snowblowers";
            } else if (option === "W") {
                return "Water Pumps";
            } else if (option === "N") {
                return "None";
            } else {
                return "N/A";
            }
        });
    }
    function getMotor(options: string[]) {
        return options.map((option) => {
            if (option === "OFF") {
                return "Off Road";
            } else if (option === "ON") {
                return "On Road";
            } else if (option === "N") {
                return "None";
            } else {
                return "N/A";
            }
        });
    }

    function getAtv(options: string[]) {
        return options.map((option) => {
            if (option === "A") {
                return "ATV";
            } else if (option === "S") {
                return "Side by Side";
            } else if (option === "N") {
                return "None";
            } else {
                return "N/A";
            }
        });
    }

    const [filterTable, setFilterTable] = useState("");

    const data = useMemo(() => {
        if (participants && participants.length > 0) {
            return participants.map((participant: MainDbReturnTypes.Participant) => {
                return {
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                    phone: participant.phone,
                    province: participant.province,
                    language: participant.preferredLanguage,
                    postal: participant.postal || "N/A",
                    powerEquipment: participant.metadata?.htl_tmp ? getPower(participant.metadata.htl_tmp).join(",\n") : "N/A",
                    motorCycles: participant.metadata?.bill_no_tmp ? getMotor(participant.metadata.bill_no_tmp).join(",\n") : "N/A",
                    marine: participant.metadata?.t_youtube_url ? participant.metadata?.t_youtube_url : "N/A",
                    atv: participant.metadata?.company_name_tmp ? getAtv(participant.metadata?.company_name_tmp).join(",\n") : "N/A",
                    creationTime: new Date(participant.creationTime),
                    updateTime: new Date(participant.updateTime),
                    sessionKey: participant.sessionKey,
                    rules: participant.metadata?.rules.toLocaleString() || "N/A",
                    optin1: participant.metadata?.optin1.toLocaleString() || "N/A",
                    event: participant.metadata?.event || "N/A",
                    registrationTime: new Date(participant.creationTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    }),
                    registrationDate: new Date(participant.creationTime).toLocaleDateString("en-CA"),
                };
            });
        }
    }, [participants]);

    const reportData = useMemo(() => {
        if (data) {
            return data.map(
                (rp: {
                    firstName: any;
                    lastName: any;
                    email: any;
                    optin1: any;
                    postal: any;
                    province: any;
                    creationTime: string | number | Date;
                    updateTime: any;
                    phone: any;
                    language: any;
                    powerEquipment: any;
                    motorCycles: any;
                    marine: any;
                    atv: any;
                    rules: any;
                    event: any;
                }) => {
                    return {
                        "First Name": rp.firstName,
                        "Last Name": rp.lastName,
                        "Email Address": rp.email,
                        "Marketing Opt-In": rp.optin1,
                        Postal: rp.postal,
                        Province: rp.province,
                        "Registration Date": new Date(rp.creationTime).toLocaleDateString("en-CA"),
                        "Registration Time": new Date(rp.creationTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                        }),
                        "Last Action": rp.updateTime,
                        Phone: rp.phone,
                        "Preferred Language": rp.language,
                        "Power Equipment": rp.powerEquipment,
                        "Motor Cycles": rp.motorCycles,
                        Marine: rp.marine,
                        ATV: rp.atv,
                        "Rules/Terms": rp.rules,
                        Event: rp.event,
                    };
                }
            );
        }
    }, [data]);

    const columns = TableColumns();

    if (isLoadingParticipants) {
        return <PageLoader />;
    }

    return !isLoadingParticipants && participants && participants.length > 0 ? (
        <>
            <div className="main__head">
                <h2 className="main__title">Participants</h2>

                <div className="main__actions">
                    <div className="search search--alt">
                        <div className="search__row search__row--input">
                            <CSVLink
                                filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                                className="btn btn--medium btn--mobile-small"
                                data={reportData ? reportData : ""}
                                asyncOnClick={true}
                                target="_blank">
                                Download Report
                            </CSVLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main__body">
                <div className="tabs js-tabs">
                    <div className="search search--alt search--medium main__search">
                        <form action="?" method="get">
                            <div className="search__row">
                                <label htmlFor="q" className="hidden">
                                    Search
                                </label>
                                <input
                                    type="search"
                                    name="q"
                                    id="q"
                                    value={filterTable}
                                    placeholder="Search"
                                    className="search__field"
                                    onChange={(e) => setFilterTable(e.target.value)}></input>
                                <button type="submit" className="search__btn">
                                    <svg className="ico-search">
                                        <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                    <Table columns={columns} data={data ? data : []} tablePageSize={15} filterValue={filterTable} />
                </div>
            </div>
        </>
    ) : (
        <p>Currently there are no any participants to display.</p>
    );
}

export default Participants;
