import {TableProps } from "react-table";


export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Event",
      accessor: "eventName",
    },
    {
      Header: "Grand total Entries",
      accessor: "grandEntries",
      width: "15%",
    },
    {
      Header: "Grand total Optins",
      accessor: "grandOptins",
      width: "15%",
    },
    {
      Header: "No of Entries",
      accessor: "entries",
      width: "15%",
    },
    {
      Header: "No of Optins",
      accessor: "optins",
      width: "15%",
    },
  ];
  return COLUMNS;
};
