import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import { byKey, emailRegex, looseNorthAmericanPhoneRegex, stringRegex } from "../../helpers/utils";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

type RegisterForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  province: string;
  address: string;
  address2: string;
  postal: string;
  metadata: { rules: boolean; optin1: boolean; rules1: boolean };
};

function Participant() {
  const params = useParams();

  const navigate = useNavigate();

  const [isSubmit, setIsSubmit] = useState(false);

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log(user.attributes.profile);
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>();

  const { isLoading: isLoadingParticipant, data: user } = useQuery("getParticipant", async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {
      sessionKey: params.key,
    });
    return res.participants[0];
  });

  const { isLoading: isLoadingWinners, data: prizeWinners } = useQuery(
    "getPrizeWinners",
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
        sessionKey: params.key,
      });

      return res.prizeWinners;
    }
  );

  async function handleRegister(values: RegisterForm) {
    setIsSubmit(true);

    const params = {
      campaignKey: prizeWinners![0].campaignKey,
      sessionKey: user!.sessionKey,
      ...values,
    };

    try {
      await campaignClient.call("addParticipantData", params);
      setIsSubmit(false);
      setMessage({ ...message, msg: "Successful updated." });
      setTimeout(() => {
        setMessage({ error: false, msg: "" });
      }, 2000);
    } catch (e) {
      setIsSubmit(false);
    }
  }

  if (isLoadingParticipant || isLoadingWinners) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participant details</h2>
        <div className="main__actions">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/participants");
            }}>
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>
      </div>
      <div className="main__body main__body--flex">
        <div className="main__content">
          <div className="formWrapper">
             <form onSubmit={handleSubmit(handleRegister)}>
            <div className="form__body">
              <h4 className="form__title">Info</h4>

              <div className="form__row">
                <label htmlFor="firstName" className="form__label">
                  First name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="firstName"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "Please enter first name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid first name.",
                      },
                    })}
                    defaultValue={user?.firstName!}
                    disabled={profile !== "Admin"}
                  />
                </div>
                {errors.firstName && (
                  <p className="error-message">
                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="lastName" className="form__label">
                  Last name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="lastName"
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Please enter last name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid last name.",
                      },
                    })}
                    defaultValue={user?.lastName!}
                    disabled={profile !== "Admin"}
                  />
                </div>
                {errors.lastName && (
                  <p className="error-message">
                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                    {errors.lastName.message}
                  </p>
                )}
              </div>
              {user?.email && (
                <div className="form__row">
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter email.",
                        },
                        pattern: {
                          value: emailRegex,
                          message: "Please enter a valid email.",
                        },
                      })}
                      defaultValue={user.email}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.email && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.email.message}
                    </p>
                  )}
                </div>
              )}

              {user?.phone && (
                <div className="form__row">
                  <label htmlFor="phone" className="form__label">
                    Phone number
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter phone.",
                        },
                        pattern: {
                          value: looseNorthAmericanPhoneRegex,
                          message: "Please enter a valid phone.",
                        },
                      })}
                      defaultValue={user?.phone!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.phone && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              )}

              {user?.postal && (
                <div className="form__row">
                  <label htmlFor="postal" className="form__label">
                    Postal
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("postal", {
                        required: {
                          value: true,
                          message: "Please enter postal.",
                        },
                      })}
                      defaultValue={user?.postal!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.postal && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.postal.message}
                    </p>
                  )}
                </div>
              )}
              {user?.address && (
                <div className="form__row">
                  <label htmlFor="address" className="form__label">
                    Address
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("address", {
                        required: {
                          value: true,
                          message: "Please enter address.",
                        },
                      })}
                      defaultValue={user?.address!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.address && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.address.message}
                    </p>
                  )}
                </div>
              )}
              {user?.address && (
                <div className="form__row">
                  <label htmlFor="address2" className="form__label">
                    Address 2
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("address2")}
                      defaultValue={user?.address2!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.city && (
                <div className="form__row">
                  <label htmlFor="city" className="form__label">
                    City
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("city")}
                      defaultValue={user.city}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.province && (
                <div className="form__row">
                  <label htmlFor="province" className="form__label">
                    Province
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("province")}
                      defaultValue={user.province}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.metadata.rules && (
                <div className="form__row">
                  <label htmlFor="postal" className="form__label">
                    Rules
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("metadata.rules")}
                      defaultValue={user?.metadata.rules ? "Yes" : "No"}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <br/>
            <br/>

            <div className="form__actions">
              {isSubmit ? (
                <button
                  type="button"
                  className="form__btn btn btn--secondary btn--secondary-alt"
                  disabled>
                  <Spinner as="span" animation="border" role="status" aria-hidden="true" /> Update
                </button>
              ) : (
                profile === "Admin" && (
                  <button type="submit" className="form__btn btn btn--secondary btn--secondary-alt">
                    Update
                  </button>
                )
              )}
            </div>
          </form>
          </div>
         
        </div>
      </div>

      <div className="alerts">
        <div
          className={`${message.msg ? "alert alert--wide is-shown" : "alert alert--wide "} `}
          id="alert-email">
          <img className="alert__icon" src="/assets/images/svg/ico-check-circle.svg" alt="" />

          <span>{message.msg}</span>

          <a href="#" className="alert__close js-alert-close">
            <svg className="ico-cross">
              <image xlinkHref="/assets/images/svg/ico-cross.svg" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

export default Participant;
