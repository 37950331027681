import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./EventTableCols";
import { TotalColumns } from "./TotalColumns";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import axios from "axios";

interface Map {
    [key: string]: string | undefined;
}

export const dateRanges = [
    "01-09-2024 - 01-23-2024",
    "01-24-2024 - 02-06-2024",
    "02-07-2024 - 02-20-2024",
    "02-21-2024 - 03-05-2024",
    "03-06-2024 - 03-19-2024",
    "03-20-2024 - 04-02-2024",
    "04-03-2024 - 04-16-2024",
    "04-17-2024 - 04-30-2024",
    "05-01-2024 - 05-14-2024",
    "05-15-2024 - 05-28-2024",
    "05-29-2024 - 06-11-2024",
    "06-12-2024 - 06-25-2024",
    "06-26-2024 - 07-09-2024",
    "07-10-2024 - 07-23-2024",
    "07-24-2024 - 08-06-2024",
    "08-07-2024 - 08-20-2024",
    "08-21-2024 - 09-03-2024",
    "09-04-2024 - 09-17-2024",
    "09-18-2024 - 10-01-2024",
    "10-02-2024 - 10-15-2024",
    "10-16-2024 - 10-31-2024",
];

const eventData: Map = {
    pcz: "PCZ Dealers   = hondapromotions.ca/?event=pcz",
    prz: "PRZ Dealers  = hondapromotions.ca/?event=prz",
    popkum: "Popkum  = hondapromotions.ca/?event=popkum",
    erag: "Traction eRag   = hondapromotions.ca/?event=erag",
    ar: "Alberta Rides   = hondapromotions.ca/?event=ar",
    cwa: "Canadian Wilderness adventures     = hondapromotions.ca/?event=cwa",
    az: "AZ Dealers   = hondapromotions.ca/?event=az",
    cz: "CZ Dealers   = hondapromotions.ca/?event=cz",
    OFA: "OFA   = hondapromotions.ca/?event=OFA",
    RJ: "RJ Motosports   = hondapromotions.ca/?event=RJ",
    TT: "Trail Tours  = hondapromotions.ca/?event=TT",
    LC: "Learning Curves   = hondapromotions.ca/?event=LC",
    GD: "Gopher Dunes   =   hondapromotions.ca/?event=GD",
    OFTR: "OFTR    = hondapromotions.ca/?event=OFTR",
    ez: "Eng Easter Zone dealers   = hondapromotions.ca/?event=ez",
    EZ: "French Easter Zone dealers =  hondapromotions.ca/?event=EZ",
    UPA: "UPA partnership = hondapromotions.ca/?event=UPA",
    XTOWN: "X-Town JRR vendor = hondapromotions.ca/?event=XTOWN",
    FRA: "Franklin JRR vendor = hondapromotions.ca/?event=FRA ",
    DES: "DESCHAMBAULT JRR vendor = hondapromotions.ca/?event=DES",
    GWQ: "Goldwing Quebec association = hondapromotions.ca/?event=GWQ",
    MQ: "Moto Quebec = hondapromotions.ca/?event=MQ",
    SM: "Le show de la Moto = hondapromotions.ca/?event=SM",
    CL: "La Classique = hondapromotions.ca/?event=CL",
    fqdc: "fqdc = hondapromotions.ca/?event=fqdc",
    hocl: "HOCL =  hondapromotions.ca/?event=hocl",
    indy: "Indy =  hondapromotions.ca/?event=indy",
    social: "Social =  hondapromotions.ca/?event=social",
};
const hardcodedEventName = "2nyye3dkh3pn2urvgdbtpzwmjq";

function Overview() {
    dayjs.extend(isBetween);
    const regExpEvents = /&fbclid|&utm_source|&utm_medium/g;
    const [selectedDateRange, setSelectedRange] = useState("");

    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getAllParticipants", async () => {
        const res: any = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants || res.result.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants.map((p:any)=>{
            return p.metadata && p.metadata.creationTime ? {
                ...p,
                creationTime: p.metadata.creationTime
            } : p
        });
    });

    const data = useMemo(() => {
        const order = [
            "no event",
            "pcz",
            "prz",
            "popkum",
            "erag",
            "ar",
            "cwa",
            "az",
            "cz",
            "OFA",
            "RJ",
            "TT",
            "LC",
            "GD",
            "OFTR",
            "ez",
            "EZ",
            "UPA",
            "XTOWN",
            "FRA",
            "DES",
            "GWQ",
            "MQ",
            "SM",
            "CL",
            "fqdc",
            "hocl",
            "indy",
            "social",
        ];

        if (participants) {
            if (selectedDateRange) {
                const filteredParticipants = participants.filter((p: any) => {
                    return checkDateRage(selectedDateRange, p.creationTime);
                });
                const allGrand = participants.filter((p: any) => {
                    return checkGrandDateRage(selectedDateRange, p.creationTime);
                });
                const allGrandOptins = allGrand.filter((i: { metadata: { optin1: any } }) => i.metadata.optin1);
                const total_events = participants
                    .map((participant: { metadata: { event: any } }) => participant.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event"))
                    .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);

                const totals_1 = total_events.map((event: string) => {
                    const eventParticipants = filteredParticipants.filter(
                        (i: { metadata: { event: string } }) => i.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event") === event
                    );
                    return {
                        eventName: event,
                        grandEntries: allGrand.filter(
                            (i: { metadata: { event: string } }) => i.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event") === event
                        ).length,
                        grandOptins: allGrandOptins.filter(
                            (i: { metadata: { event: string } }) => i.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event") === event
                        ).length,
                        entries: eventParticipants.length,
                        optins: eventParticipants.filter((i: { metadata: { optin1: any } }) => i.metadata.optin1).length,
                    };
                });

                const otherEvents = totals_1
                    .filter((ev: { eventName: string }) => ev.eventName?.length >= 50 || ev.eventName === hardcodedEventName)
                    .map((r: { eventName: any }) => {
                        return {
                            ...r,
                            eventName: `Other`,
                        };
                    });

                const unmatched = totals_1
                    .filter((ev: { eventName: string }) => !order.some((ev2) => ev.eventName === ev2) && ev.eventName?.length < 50 && ev.eventName !== hardcodedEventName)
                    .map((r: { eventName: any }) => {
                        return {
                            ...r,
                            eventName: `${r.eventName} = hondapromotions.ca/?event=${r.eventName}`,
                        };
                    });
                const temp = order.map((event) => {
                    let matched = totals_1.filter((ev: any) => ev.eventName === event);

                    if (matched.length > 0) {
                        return matched[0];
                    } else {
                        return {
                            eventName: event,
                            grandEntries: 0,
                            grandOptins: 0,
                            entries: 0,
                            optins: 0,
                        };
                    }
                });

                const combinedEvents = [...temp, ...unmatched];

                const updateEventNames = combinedEvents.map((event) => {
                    if (event.eventName == "no event") {
                        return {
                            ...event,
                            eventName: ["English: hondapromotions.ca ", "French: hondapromotions.ca"],
                        };
                    } else {
                        return {
                            ...event,
                            eventName: eventData[`${event.eventName}`] ? eventData[`${event.eventName}`] : event.eventName,
                        };
                    }
                });

                let other_row = {
                    eventName: "Other",
                    grandEntries: otherEvents.map((event: any) => event.grandEntries).reduce((a: number, b: number) => a + b, 0),
                    grandOptins: otherEvents.map((event: any) => event.grandOptins).reduce((a: number, b: number) => a + b, 0),
                    entries: otherEvents.map((event: any) => event?.entries).reduce((a: number, b: number) => a + b, 0),
                    optins: otherEvents.map((event: any) => event?.optins).reduce((a: number, b: number) => a + b, 0),
                };
                const total = [...updateEventNames, ...otherEvents];
                let total_row = {
                    eventName: "Total",
                    grandEntries: total.map((event) => event.grandEntries).reduce((a, b) => a + b, 0),
                    grandOptins: total.map((event) => event.grandOptins).reduce((a, b) => a + b, 0),
                    entries: total.map((event) => event?.entries).reduce((a, b) => a + b, 0),
                    optins: total.map((event) => event?.optins).reduce((a, b) => a + b, 0),
                };

                updateEventNames.push(other_row, total_row);
                return updateEventNames;
            } else {
                const total_events = participants
                    .map((participant: { metadata: { event: any } }) => participant.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event"))
                    .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);

                const totals_1 = total_events.map((event: string) => {
                    const eventParticipants = participants.filter(
                        (i: { metadata: { event: string } }) => i.metadata.event.replace(regExpEvents, "").replace("no%20event", "no event") === event
                    );

                    let updated = {
                        eventName: event,
                        grandTotals: {
                            entries: eventParticipants.length,
                            optins: eventParticipants.filter((i: { metadata: { optin1: any } }) => i.metadata.optin1).length,
                        },
                    };
                    dateRanges.map((date, index) => {
                        Object.assign(updated, {
                            [`week${index}`]: {
                                daterange: date,
                                entries: eventParticipants.filter((t: { creationTime: string }) => checkDateRage(date, t.creationTime)).length,
                                optins: eventParticipants.filter((p: any) => p.metadata.optin1).filter((t: { creationTime: string }) => checkDateRage(date, t.creationTime)).length,
                            },
                        });
                    });

                    return updated;
                });

                const otherEvents = totals_1
                    .filter((ev: { eventName: string }) => ev.eventName?.length >= 50 || ev.eventName === hardcodedEventName)
                    .map((r: { eventName: any }) => {
                        return {
                            ...r,
                            eventName: `Other`,
                        };
                    });

                const unmatched = totals_1
                    .filter((ev: { eventName: string }) => !order.some((ev2) => ev.eventName === ev2) && ev.eventName?.length < 50 && ev.eventName !== hardcodedEventName)
                    .map((r: { eventName: any }) => {
                        return {
                            ...r,
                            eventName: `${r.eventName} = hondapromotions.ca/?event=${r.eventName}`,
                        };
                    });

                const temp = order.map((p) => {
                    let matched = totals_1.filter((ev: { eventName: string }) => ev.eventName === p);
                    if (matched.length > 0) {
                        return matched[0];
                    } else {
                        let sample = {
                            eventName: p,
                            grandTotals: { entries: 0, optins: 0 },
                        };
                        dateRanges.map((date, index) => {
                            Object.assign(sample, {
                                [`week${index}`]: {
                                    daterange: date,
                                    entries: 0,
                                    optins: 0,
                                },
                            });
                        });
                        return sample;
                    }
                });

                const combinedEvents = [...temp, ...unmatched];
                const updateEventNames = combinedEvents.map((event) => {
                    if (event.eventName == "no event") {
                        return {
                            ...event,
                            eventName: ["English: hondapromotions.ca ", "French: hondapromotions.ca"],
                        };
                    } else {
                        return {
                            ...event,
                            eventName: eventData[`${event.eventName}`] ? eventData[`${event.eventName}`] : event.eventName,
                        };
                    }
                });

                let other_row = {
                    eventName: "Other",
                    grandTotals: {
                        optins: otherEvents.map((event: { grandTotals: { optins: any } }) => event.grandTotals.optins).reduce((a: number, b: number) => +a + +b, 0),
                        entries: otherEvents.map((event: { grandTotals: { entries: any } }) => event.grandTotals.entries).reduce((a: number, b: number) => +a + +b, 0),
                    },
                };
                dateRanges.map((date, index) => {
                    Object.assign(other_row, {
                        [`week${index}`]: {
                            daterange: date,
                            entries: otherEvents.map((event: any) => event[`week${index}`]?.entries).reduce((a: number, b: number) => a + b, 0),
                            optins: otherEvents.map((event: any) => event[`week${index}`]?.optins).reduce((a: number, b: number) => a + b, 0),
                        },
                    });
                });
                const total = [...updateEventNames, ...otherEvents];
                let total_row = {
                    eventName: "Total",
                    grandTotals: {
                        optins: total.map((event) => event.grandTotals.optins).reduce((a, b) => +a + +b, 0),
                        entries: total.map((event) => event.grandTotals?.entries).reduce((a, b) => a + b, 0),
                    },
                };
                dateRanges.map((date, index) => {
                    Object.assign(total_row, {
                        [`week${index}`]: {
                            daterange: date,
                            entries: total.map((event: any) => event[`week${index}`]?.entries).reduce((a, b) => a + b, 0),
                            optins: total.map((event: any) => event[`week${index}`]?.optins).reduce((a, b) => a + b, 0),
                        },
                    });
                });
                updateEventNames.push(other_row, total_row);

                return updateEventNames;
            }
        }
    }, [participants, selectedDateRange]);

    const reportData = useMemo(() => {
        if (data) {
            if (selectedDateRange) {
                return data.map((event: any) => {
                    return {
                        "Event Name": event.eventName,
                        "Grand Total of Entries": event.grandEntries,
                        "Grand Total of Optins": event.grandOptins,
                        Entries: event?.entries,
                        Optins: event.optin1,
                    };
                });
            } else {
                return data.map((event: any) => {
                    const report: { [key: string]: any } = {
                        "Event Name": event.eventName,
                        "Grand Total of Entries": event.grandTotals?.entries,
                        "Grand Total of Optins": event.grandTotals.optins,
                    };
                    dateRanges?.forEach((d: string, i: number) => {
                        report[`${d} Entries`] = event[`week${i}`].entries;
                        report[`${d} Optins`] = event[`week${i}`].optins;
                    });
                    return report;
                });
            }
        }
    }, [data]);

    function checkDateRage(dateRange: string, date: string) {
        const [startDate, endDate] = dateRange.split(" - ");
        return dayjs(date).isBetween(startDate, endDate, "day", "[]");
    }

    function checkGrandDateRage(dateRange: string, date: string) {
        const [_, endDate] = dateRange.split(" - ");
        return dayjs(date).isBefore(endDate);
    }
    const columns = TableColumns();
    const totalcolumns = TotalColumns(dateRanges);

    if (isLoadingParticipants) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head">
                <h2 className="main__title">Overview</h2>
            </div>
            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="boxes-info">
                    <select
                        style={{
                            width: "22rem",
                            margin: "10px 0px",
                            padding: "5px 2px",
                            fontSize: "16px",
                            boxShadow: "none",
                            backgroundColor: "#fff",
                            paddingLeft: "10px",
                            borderRadius: "4px",
                        }}
                        value={selectedDateRange}
                        onChange={(e) => {
                            setSelectedRange(e.currentTarget.value);
                        }}>
                        <option value="">All Dates</option>
                        {dateRanges &&
                            dateRanges.map((v: any) => (
                                <option value={v} key={v}>
                                    {v}
                                </option>
                            ))}
                    </select>

                    <div style={{ float: "right" }}>
                        <div className="search__row search__row--input">
                            <CSVLink
                                filename={`event-Report-${new Date().toLocaleDateString()}`}
                                className="btn btn--medium btn--mobile-small"
                                data={reportData ? reportData : ""}
                                asyncOnClick={true}
                                target="_blank">
                                Download Report
                            </CSVLink>
                        </div>
                    </div>
                </div>

                <br />
                {selectedDateRange && (
                    <div className="tabs__body mt-5">
                        <div className="table table--alt table--tabs table--big">
                            <Table columns={columns} data={data ? data : []} tablePageSize={15} />
                        </div>
                    </div>
                )}
                {!selectedDateRange && <Table columns={totalcolumns} data={data ? data : []} tablePageSize={15} />}
            </div>
        </>
    );
}

export default Overview;
