import { TableProps } from "react-table";


export const TotalColumns = (dateRanges: string[]) => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Event",
      accessor: "eventName",
      width: "15%",
    },
    {
      Header: "Grand Total Contest to date",
      columns: [
        {
          Header: "Entries",
          accessor: "grandTotals.entries",
          width: "15%",
        },
        {
          Header: "Optins",
          accessor: "grandTotals.optins",
          width: "15%",
        },
      ],
    },

    ...(dateRanges &&
      dateRanges.map((dateRange, index) => {
        return {
          Header: dateRange,
          columns: [
            {
              Header: "Entries",
              width: "15%",
              accessor: `week${index}.entries`,
            },
            {
              Header: "Optins",
              width: "15%",
              accessor: `week${index}.optins`,
            },
          ],
        };
      })),

 
  ];
  return COLUMNS;
};
